import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  items: [],
  company: null,
  showWarnings: false,
  balance: null,
  due: null,
  creditNotes: [],
  pdfDownloadable: false,
  offers: [],
  creditOffers: [],
  amount: null,
  totalAmount: null,
  vatAmount: null
});

export default () => ({
  ...initialCrudState(initialModel),
  user: {},
  employee: {},
  company: {},
  address: {},
  country: {},
  defaultItem: {
    description: null,
    quantity: null,
    unitPrice: null,
    totalPrice: null,
    offers: []
  },
  companyVatRate: null,
  companyRejectableTerm: null,
  tempItem: null,
  invoiceableOffers: [],
  offer: {},
  lead: {},
  submission: {},
  isLoadingOffers: false,
  distributedUntill: null,
  isUpdatingItem: false
});
